
import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Card, Image, CardBody, CardHeader, CardTitle, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSearch, faTimes, faAngleRight, faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Login from './Login';
import ImageNavbar from '../../assets/images/Carousel.svg';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import "../../assets/css/header.css";
import logo from '../../assets/images/logo.png'; // Adjust path as needed
import Cart_img from '../../assets/images/Cart.png'; // Adjust path as needed
import auth_img from '../../assets/images/user.png';
import heart_img from '../../assets/images/Heart-1.svg';
import cat_menu from '../../assets/images/cat_image.png';
import menubars from '../../assets/images/pepicons-pencil_menu.svg';
import iconsearch from '../../assets/images/bi_search.svg';
import iconclose from '../../assets/images/hugeicons_cancel-circle.svg';
import logincircle from '../../assets/images/1-login.png';
import { FaFontAwesome } from 'react-icons/fa'; 
import { unstable_renderSubtreeIntoContainer } from 'react-dom';


function Header() {
  const buttonRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState('');
  const token = sessionStorage.getItem("tokenId");
  const UserId= sessionStorage.getItem("userId");//Very Important

  $("#backgroundbody").removeClass('backgroundbody');
  $("#sidenav").hide();
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebarOpen = () => {
    debugger;
    $("#sidenav").fadeIn(200);
    $("#backgroundbody").addClass('backgroundbody');
    return false;
  };
  const toggleSidebarClose = () => {
    $("#sidenav").fadeOut(200);
    $("#backgroundbody").removeClass('backgroundbody');
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Final OTP Submitted:", otp);
    // Add your OTP verification logic here
  };

  useEffect(() => {
    fetchBanners();

  }, []);
  useState(() => {
    $("#sidenav").hide();

  }, []);
  function CartDisplay(){
    $(".dvCart").show();
  }
  const fetchBanners = async () => {
    fetch('https://rollerfashions.adef.tech/api/Home/Home_Category') // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {

        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }; // Empty dependency array means this effect runs once on mount

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="bg-light text-black sidebar" id='sidenav'>
        <div className='side_navheader'>
          <Row >
            <Col sm='8'><Button className='menu_toggle_button_inner larger' onClick={handleShow}>Login/Signup &nbsp;</Button></Col>
            <Col sm='4'><Button className='menu_toggle_button' onClick={toggleSidebarClose}><Image src={iconclose}></Image></Button></Col>
          </Row>
        </div>
        <div className='sidenavcontent'>
          <ul className="nav flex-column">

            {data.map((item, index) => (
              <li className="nav-item">
                <span className='span_link'>{item.name}<FontAwesomeIcon className='angleright' icon={faAngleRight} size="1x" /></span>
                <ul className='submenu'>
                 <Row>
                  <Col md={6}>
                  <li><a className='AllLi'><b>All Products</b></a></li>
                  {item.subcat.map((subitem) => (
                    <li><a href={"/allproducts?Category_ID="+item.id+"&Subcategory_ID="+subitem.id}>{subitem.name}</a></li>

                  ))}
                  </Col>
                  <Col md={6}>
                  <Image src={cat_menu} width={200} className='cat_image'></Image>
                  </Col>
                 </Row>
                </ul>

              </li>


            ))}
            <li className="nav-item">
              <a href='../allproducts'>  <span className='span_link'>Combo</span></a>
            </li>

            <li className="nav-item">
              <a href="../allproducts"><span className='span_link'>Sales</span></a>
            </li>
            <hr></hr>
            <li className="nav-item">
              <a href="../Account"><span className='span_link'>TRACK ORDERS</span></a>
            </li>

            <li className="nav-item">
              <a href="../Acount"><span className='span_link'>PROFILE</span></a>
            </li>

            <li className="nav-item">
              <a href="#contact"><span className='span_link'>CONTACT US</span></a>
            </li>
            <hr></hr>
            <li className="nav-item">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} size="1x" />
      </a>
 &nbsp; &nbsp;
      {/* Instagram Icon */}
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="1x" />
      </a>
            </li>

          </ul>
        </div>
      </div>
      <Navbar bg="light" className="padding fixed">
        <Container fluid >
          <Nav className="me-auto">

            <Button onClick={toggleSidebarOpen} className="menu_toggle_button">
              <Image src={menubars} width={40} />
            </Button>


            <Navbar.Brand className="ml-auto" href="#home"><a href='/'><Image className='brand-image' src={logo} /></a></Navbar.Brand>
          </Nav>


          <Nav className="ms-auto"> </Nav>
          <Nav className="ms-auto">
          <div class="search-box"><Image src={iconsearch} className='fa-search' ></Image><input type='text' placeholder='Search' className='form-control large-textbox'></input></div>
          &nbsp;&nbsp;   &nbsp;&nbsp;<Image src={heart_img} width={40} ></Image>
              &nbsp;&nbsp;   &nbsp;&nbsp;
            <Image className='CartIcon' onClick={CartDisplay} src={Cart_img} ref={buttonRef} width={40}></Image>&nbsp;&nbsp;   &nbsp;&nbsp;
            {UserId?(
            <a href='../Account'><Image src={auth_img} width={40} title='My Account'  ></Image></a>):(

              <Image onClick={handleShow} className='username' src={logincircle} title='Signin' width={40}  ></Image>
            )}
          </Nav>

          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom_model modal-lg">
            <Modal.Header className='pt-reduced pb-reduced' closeButton>

            </Modal.Header>
            <Modal.Body closeButton>
<Login></Login>

            </Modal.Body>

          </Modal>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;