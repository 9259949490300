import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import "../../assets/css/footer.css";
import playstore from "../../assets/images/playstore.png";
import appstore from "../../assets/images/appstore.png";
import logo from '../../assets/images/logo.png'; // Adjust path as needed
function Footer() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchCategories();
    
      }, []);
    
    
      const fetchCategories = async () => {
        fetch('https://rollerfashions.adef.tech/api/Home/Home_Category') // Replace with your API endpoint
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
          
            setData(data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }; // Empty dependency array means this effect runs once on mount
    
      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error.message}</div>;
    
    return (
        <div id="#contact" className='footerdv'>
        <Container> {/* Add fluid to make the container fluid */}
            <Row>
                <Col md={3}>
<Image src={logo} className='logof'></Image>
                </Col>

                <Col md={3}>

                    <ul>
                        <li><h4>Useful Links</h4></li>
                        <li>
                            <a href='#'>About us</a>
                        </li>
                        <li>
                            <a href='#'>Size guide</a>
                        </li>
                        <li>
                            <a href='#'>Track Order</a>
                        </li>
                        <li>
                            <a href='/privacy'>Privacy Policy</a>
                        </li>
                        <li>
                            <a href='/tandc'>Terms & Conditions</a>
                        </li>
                        <li>
                            <a href='#'>Gift Voucher</a>
                        </li>
                    </ul>

                </Col>

                <Col md={3}>
                    <ul>
                    <li><h4>Categories</h4></li>
                    {data.map((item, index) => (
                       
                        <li key={index}>
                            <a href='#'>{item.name}</a>
                        </li>

                    ))}
                    </ul>
                </Col>

                <Col md={3} className='thirdparty'>
                    <ul>
                        <li><h4>Donwnload App</h4></li>
                        <li>
                            
                            <a href='#'><Image width={100} src={appstore}></Image> </a>
                        </li>
                        <li>
                            
                            <a href='#'><Image width={100} src={playstore}></Image> </a>
                        </li>


                    </ul>

                </Col>
            </Row>
 </Container>
 <hr></hr>
<Container>
            <Row>
                <Col md={12}>
                    <footer className="powered">
                        <p>All Copyrights reserved by <a href="https://codingster.in" target="_blank">Codingster pvt ltd.</a></p>
                    </footer>
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default Footer;
