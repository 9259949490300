import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/css/profile.css";
import Header from '../Customer/Header';
import Login from '../Customer/Login';
import AddAddress from '../Customer/AddAddress';
import iconclose from '../../assets/images/53235-200.png';
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import Footer from "./Footer";
function Address() {
    const buttonRef = useRef(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [isaddressVisible, setisaddressVisible] = useState(false);
    const token = sessionStorage.getItem("tokenId");
    const UserId = sessionStorage.getItem("userId");//Very Important
    const [error, setError] = useState(null);
    useEffect(() => {
        debugger;
        fetchAccountDetails();

    }, []);

    const addressClose = ()=>{
        setisaddressVisible(!isaddressVisible);
    }
    const addressOpen = ()=>{
        setisaddressVisible(!isaddressVisible);
    }
   
    const fetchAccountDetails = async () => {
        debugger;
        if (UserId != null) {
            const url = `https://rollerfashions.adef.tech/api/Addressbook/List_Address?User_ID=${UserId}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            debugger;
            setData(response.data);
        }
        else {
            setIsLoginVisible(!isLoginVisible);
        }

    }; // Empty dependency array means this effect runs once on mount

    return (
        <>
            <Header />
            <div className='dvProfile'>
           
            <Container>
             <h3><a className='aback' href='../account'>&#8592;</a></h3>
                <Row>
                {data.map((item) => (
                    <Col md={4}>
                      <Card className='card'>
                        <h5>{item.name}</h5>
                        <span>{item.flatno} {item.address},</span>
                        <span>{item.area}, {item.city}</span>
                        <span>{item.state}, {item.country}</span>
                        <span>{item.pincode}</span>
                        <br></br>
                        <h5>{item.phone}</h5>
                      </Card>
                    </Col>))}
                </Row>
                <br></br>
                <Row>
                    <Col md={3}>
                    <Button onClick={addressOpen}>Add new address +</Button>
                    </Col>
                </Row>
            </Container>
            </div>
            {isLoginVisible && (
            <div className='divpop'>
            <Login></Login>
            </div>
            )}
            {isaddressVisible &&(
                <>
                 <Image  width={40} onClick={addressClose} className="btnCloseAddress" src={iconclose}></Image>
         
             
                <div className='divpop'>
                    <AddAddress></AddAddress>
                </div>
            </>
            )}
            <Footer />
        </>

    )
}
export default Address;