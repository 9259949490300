// import React, { useState, useEffect } from 'react';
// import '../../assets/css/topCategories.css';
// import TopCategories1 from "../../assets/images/Rectangle6.png"
// import TopCategories2 from "../../assets/images/Rectangle9.png"
// import { Row, Col, Container, Image } from 'react-bootstrap';

// function TopCategories() {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     useEffect(() => { 
//         fetchFeatured_Subcat();

//     }, []);
//     const fetchFeatured_Subcat = async () => {
//         debugger;
//         fetch('https://rollerfashions.adef.tech/api/Home/Featured_Subcategory') // Replace with your API endpoint
//             .then((response) => {
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 return response.json();
//             })
//             .then((data) => {
//                 debugger;
//                 setData(data);
//                 setLoading(false); 
//             })
//             .catch((error) => {
//                 setError(error);
//                 setLoading(false);
//             });
//     }; // Empt
//     if (loading) return <div>Loading...</div>;
//     if (error) return <div>Error: {error.message}</div>;
//     return (
//         <div className='padding'>
//             <div className='bgcat'>
//             <div className='container-fluid'>
               
//                     <Row>
//                         {/* Column for image */}
//                         <Col className="md-12">
//                             <div className="top-categories">

//                                 <h2><b>COLLECTIONS- </b>TOP NOTCH</h2>
//                             </div>
//                         </Col>
//                     </Row>


//                     <Row>
//                     <Col md={4} className="p-4"><div className='first'>
//                            <a href={"../allproducts?Subcategory_ID="+data[0].id}> <label className='subcat_label'>{data[0].name}</label><Image src={data[0].image} className="subcat_fit"></Image></a>
//                         </div>
//                         </Col>
        
        
//                         <Col md={3} className="p-4"><div className='second'>
//                         <a href={"../allproducts?Subcategory_ID="+data[1].id}>  <label className='subcat_label'>{data[1].name}</label><Image src={data[1].image} className="subcat_fit"></Image></a>
//                         </div>
//                         </Col>
//                         <Col md={5} className="p-4"><div className='third'><a href={"../allproducts?Subcategory_ID="+data[2].id}><label className='subcat_label'>{data[2].name}</label><Image src={data[2].image} className="subcat_fit"></Image></a></div>
//                         </Col>
//                     </Row>
//                     <br></br>
//                     <Row>
//                         <Col md={4} className="p-4"><div className='fourth'><a href={"../allproducts?Subcategory_ID="+data[3].id}><label className='subcat_label'>{data[3].name}</label><Image src={data[3].image} className="subcat_fit"></Image></a></div>
//                         </Col>
//                         <Col md={5} className="p-4"><div className='fifth'><a href={"../allproducts?Subcategory_ID="+data[4].id}><label className='subcat_label'>{data[4].name}</label><Image src={data[4].image} className="subcat_fit"></Image></a></div>
//                         </Col>
//                         <Col md={3} className="p-4"><div className='sixth'><a href={"../allproducts?Subcategory_ID="+data[5].id}><label className='subcat_label'>{data[5].name}</label><Image src={data[5].image} className="subcat_fit"></Image></a></div>
//                         </Col>
//                     </Row>
                
//             </div>
//         </div>
//         </div>
//     );
// }

// export default TopCategories;

import React, { useState, useEffect } from 'react';
import '../../assets/css/topCategories.css';
import TopCategories1 from "../../assets/images/Rectangle6.png";
import TopCategories2 from "../../assets/images/Rectangle9.png";
import { Row, Col, Container, Image } from 'react-bootstrap';

function TopCategories() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchFeatured_Subcat();
    }, []);

    const fetchFeatured_Subcat = async () => {
        try {
            const response = await fetch('https://rollerfashions.adef.tech/api/Home/Featured_Subcategory');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setData(data);  // Assuming the API returns an array of objects
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // Check if data is populated and has enough elements
    if (!data || data.length < 6) {
        return <div>Not enough data to display categories</div>;
    }

    return (
        <div className='padding'>
            <div className='bgcat'>
                <div className='container-fluid'>
                    <Row>
                        <Col className="md-12">
                            <div className="top-categories">
                                <h2><b>COLLECTIONS- </b>TOP NOTCH</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Column for image */}
                        <Col md={4} className="p-4">
                            <div className='first'>
                                <a href={"../allproducts?Subcategory_ID=" + data[0].id}>
                                    <label className='subcat_label'>{data[0].name}</label>
                                    <Image src={data[0].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>

                        <Col md={3} className="p-4">
                            <div className='second'>
                                <a href={"../allproducts?Subcategory_ID=" + data[1].id}>
                                    <label className='subcat_label'>{data[1].name}</label>
                                    <Image src={data[1].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>
                        <Col md={5} className="p-4">
                            <div className='third'>
                                <a href={"../allproducts?Subcategory_ID=" + data[2].id}>
                                    <label className='subcat_label'>{data[2].name}</label>
                                    <Image src={data[2].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={4} className="p-4">
                            <div className='fourth'>
                                <a href={"../allproducts?Subcategory_ID=" + data[3].id}>
                                    <label className='subcat_label'>{data[3].name}</label>
                                    <Image src={data[3].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>
                        <Col md={5} className="p-4">
                            <div className='fifth'>
                                <a href={"../allproducts?Subcategory_ID=" + data[4].id}>
                                    <label className='subcat_label'>{data[4].name}</label>
                                    <Image src={data[4].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>
                        <Col md={3} className="p-4">
                            <div className='sixth'>
                                <a href={"../allproducts?Subcategory_ID=" + data[5].id}>
                                    <label className='subcat_label'>{data[5].name}</label>
                                    <Image src={data[5].image} className="subcat_fit" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default TopCategories;
