import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Carousel, Button, NavbarCollapse } from 'react-bootstrap';
import "../../assets/css/bottombanner.css";
function LastBanners() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {

        fetchbottom_banner();

    }, []);
    const fetchbottom_banner = async () => {
        fetch('https://rollerfashions.adef.tech/api/Home/Home_Banners?type=Bottom') // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                debugger;
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }; // Empt
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className='padding'>
<br></br><br></br>
            <Container fluid>
                <Row>
                    <Col md='5'>
                        <div className='pdleft'>
                            <a href={data[2].url}><h4 className='subcat_labels'>{data[2].description}</h4><Image src={data[2].image} className='middlebanner'></Image></a>
                        </div>
                    </Col>
                    <Col md='7'>
                        <br></br><br></br>
                        <h2 className='top-categories-heading mt-2 ms-5'><b>SET YOUR WARDROBE -</b><br></br> <span className="thin">AMAZING COLLECTIONS..!</span></h2>
                        <br></br><Row>
                            <Col md='6'>
                            <a href={data[0].url}><h4 className='subcat_labels'>{data[0].description}</h4>  <Image src={data[0].image} className='middlebanner'></Image></a></Col>
                            <Col md='6'><a href={data[1].url}><h4 className='subcat_labels'>{data[1].description}</h4><Image src={data[1].image} className='middlebanner'></Image></a></Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
            <br>
            </br>  <br>
        </br>
    <br></br>
        </div>
      )
}
export default LastBanners;